import * as React from 'react';
import Seo from "../components/seo";
import Layout from '../components/layout';

export class ProjectsPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo title="My Projects" />
        <h2 className="text-4xl font-bold mb-6 border-b pb-4 text-blue-800">My Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img src="https://via.placeholder.com/400x200" alt="Project 1" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2">Project 1</h3>
              <p className="text-gray-700 mb-4">Description of Project 1. You can easily replace this with your own project details.</p>
              <a href="#" className="text-blue-500 hover:text-blue-700">Learn More</a>
            </div>
          </div>
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            <img src="https://via.placeholder.com/400x200" alt="Project 2" className="w-full h-48 object-cover" />
            <div className="p-6">
              <h3 className="text-2xl font-bold mb-2">Project 2</h3>
              <p className="text-gray-700 mb-4">Description of Project 2. You can easily replace this with your own project details.</p>
              <a href="#" className="text-blue-500 hover:text-blue-700">Learn More</a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default ProjectsPage;
